/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/css/style.css"

//redirect main root to /en
export function onClientEntry() {
  if (window.location.pathname === "/") {
    window.location.pathname = "/en"
  }
  if (window.location.pathname === "") {
    window.location.pathname = "/en"
  }
}
